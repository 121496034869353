import React, {Component} from "react";

class AutoAdd extends Component{

    componentDidMount() {
        let token = this.props.location.query.token;
        let mac = this.props.location.query.mac;
        let device = this.props.location.query.device;
        window.localStorage.setItem("token",token);
        setTimeout(()=>{
            window.location.href = "#/account/addpllaylist?mac="+mac+"&device="+device+"&auth=mac"
        },3000);
    }

    render() {
        return(
            <div>
                loading...
            </div>
        )
    }
}

export default AutoAdd;