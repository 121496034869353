import Axios from "axios";
import { toast } from "react-toastify";
import CONFIG from "./Config";

Axios.interceptors.request.use((config) => {
  config.headers.Authorization = `${localStorage.getItem("token") || ""}`;
  return config;
});

export const getToken = () => {
  return localStorage.getItem("token");
};
export const request = (url, body, callback, method, cberror, query, pc) => {
  if (url.indexOf("undefined") > -1) {
    return;
  }

  let axios = null;
  const config = {
    onUploadProgress: function (progressEvent) {
      console.log(progressEvent);
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if (pc) {
        pc(percentCompleted);
      } else {
        console.log(pc);
      }
    },
  };
  if (method === "get") {
    body ? (body = { body }) : (body = {});
  }

  if (method === "delete") {
    axios = Axios[method](url, { data: body }, config);
  } else {
    let link = url;
    if (query) {
      link += crateInitialParams("mac", body);
    }
    axios = Axios[method || "post"](link, body, config);
  }

  axios
    .then((data) => {
      if (data.data.error) {
        if (typeof data.data.message === "array") {
          console.log("array");
          for (let i = 0; i < data.data.message.length; i++) {
            toast.error(data.data.message[i].msg);
          }
        } else {
          if (typeof cberror === "function") {
            cberror(data.data.message);
            toast.error(data.data.message);
          } else {
            toast.error(data.data.message);
          }
        }
      } else {
        callback(data.data);
      }
    })
    .catch((error) => {
      toast.error(error.message);
    });
};

export const loginByMac = (mac, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.LOGIN_MAC, { mac }, cb);
};

export const validateMacAddress = (mac, cb, err) => {
  request(
    CONFIG.API_V2 + CONFIG.ROUTES.VALIDATE_MAC,
    mac,
    cb,
    "get",
    err,
    true
  );
};

export const confirmLoginCode = (body, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.CONFIRM_LOGIN, body, cb);
};

export const loginByEmail = (email, password, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.LOGIN_EMAIL, { email, password }, cb);
};

export const getDevices = (cb) => {
  request(CONFIG.API + CONFIG.ROUTES.DEVICE, {}, cb, "get");
};

export const getDevice = (cb) => {
  request(CONFIG.API + "/device", {}, cb, "get");
};

export const addDevice = (mac, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.DEVICE_ADD, { mac }, cb);
};

export const confirmDeviceCode = (body, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.CONFIRM_DEVICE, body, cb);
};
export const addNewPlaylist = (body, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.ADD_PLAYLIST, body, () => {
    toast.success("Playlist added");
    cb();
  });
};
export const removePlaylist = (id, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.ADD_PLAYLIST, { id }, cb, "delete");
};
export const registerUser = (body, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.REGISTER, { ...body }, cb);
};

export const editPlaylist = (body, cb) => {
  request(CONFIG.API_V2 + CONFIG.ROUTES.EDIT_PLAYLIST, { ...body }, cb, "put");
};

export const getAppInfo = (cb) => {
  request(CONFIG.API + CONFIG.ROUTES.INFO, null, cb, "get");
};

export const payment = (body, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.PAY, body, cb);
};

export const parsePlaylist = (id, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.PARSE, { id }, cb);
};
export const validateToken = (cb, cberr) => {
  request(CONFIG.API + CONFIG.ROUTES.VALIDATE, null, cb, null, cberr);
};

export const activationWithCoupon = (body, cb) => {
  request(CONFIG.API_V2 + CONFIG.ROUTES.ACTIVATION_COUPON, body, cb);
};

export const paypalPayment = (
  mac,
  price,
  email,
  payment_id,
  detiles,
  cb,
  cberr
) => {
  request(
    CONFIG.API + CONFIG.ROUTES.PAYPAL,
    { mac, price, payment_id, email, detiles },
    cb,
    null,
    cberr
  );
};

export const getIp = (cb, cberr) => {
  request(CONFIG.API + CONFIG.ROUTES.IP, null, cb, "get", cberr);
};

export const RegisterReseller = (body, cb) => {
  request(
    CONFIG.RESSELER_API + CONFIG.ROUTES.RESSELLER,
    { ...body },
    cb,
    "post"
  );
};

export const ConfirmResellerCode = (code, id, cb) => {
  request(
    CONFIG.RESSELER_API + CONFIG.ROUTES.RESSELLER_CONFIRM,
    { code, id },
    cb,
    "post"
  );
};

export const voucherCodeActivation = (code, mac, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.VOUCHER_CODE, { code, mac }, cb, "post");
};

export const activateReferralDevice = (code, mac, resseler_id, cb) => {
  request(
    CONFIG.RESSELER_API + CONFIG.ROUTES.REFFERAL_ACTIVATION,
    { code, mac, resseler_id },
    cb,
    "post"
  );
};

export const addPlaylistFromFile = (form, cb, pc) => {
  request(
    CONFIG.API_V2 + "/parse_playlist",
    form,
    cb,
    "post",
    () => {
      toast("Error uploading file");
    },
    "",
    pc
  );
};

export const resetPassword = (email, cb, cberror) => {
  request(CONFIG.API_V2 + "/reset_password", { email }, cb, "post", cberror);
};

export const confirmResetPassword = (code, email, password, cb, cberror) => {
  request(
    CONFIG.API_V2 + "/confirm_reset_password",
    { code, email, password },
    cb,
    "post",
    cberror
  );
};

export const recaptcha = (body, cb) => {
  request(CONFIG.RESSELER_API + "/resseler/recaptcha", body, cb, "post");
};

export const get_external_epg_list = (cb, err) => {
  request(CONFIG.API_V2 + "/epg_list", null, cb, "get", err);
};

export const add_external_epg_list = (data, cb, err) => {
  request(CONFIG.API_V2 + "/epg_list", data, cb, "post", err);
};

export const remove_external_epg_link = (data, cb, err) => {
  request(CONFIG.API_V2 + "/epg_list", data, cb, "delete", err);
};

export const getNews = (cb,params={}) => {
  request(CONFIG.API_V2 + CONFIG.ROUTES.NEWS + buildGetQuery(params) ,null, cb, "get");
  // request("http://192.168.8.128:8000/api/v2/news"+ buildGetQuery(params), null, cb, "get");
};


export const getRelatedNews = (params, cb) => {
  request(
     CONFIG.API_V2+CONFIG.ROUTES.NEWS_NOTE_IN+ buildGetQuery(params),
    null,
    cb,
    "get"
  );
  // request(`http://192.168.8.128:8000/api/v2/news_notIn`+ buildGetQuery(params) ,null, cb, "get");
};

function buildGetQuery(object) {
  let query = "";
  for (let key in object) {
    query += key + "=" + object[key] + "&";
  }
  return "?" + query.substring(0, query.length - 1);
}

function crateInitialParams(params, obj) {
  const query = [];

  for (let key in obj) {
    query.push(params + "=" + obj[key]);
  }

  if (query.length) {
    return "?" + query.join("&");
  } else {
    return "";
  }
}
