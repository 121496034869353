import React, { Component } from "react";
import { confirmLoginCode, loginByEmail, loginByMac } from "../server";
import SimpleReactValidator from "simple-react-validator";
import PopUp from "./PopUp/PopUp";
import Loading from "./Loading";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mac: "",
      code: "",
      email: "",
      password: "",
      show: false,
      isLoading: false,
    };

    this.validator = new SimpleReactValidator();
    this.loginValidator = new SimpleReactValidator();
    this.onClose = this.onClose.bind(this);
  }
  componentDidMount() {
    console.log(this.props);
  }

  hideForceLoading() {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
  }
  onClose() {
    this.setState({
      show: false,
    });
  }
  formValid(validator, filedName) {
    if (validator.fieldValid(filedName)) {
      return true;
    } else {
      validator.showMessages();
      this.forceUpdate();
      return false;
    }
  }

  login(e) {
    e.preventDefault()
    if (this.formValid(this.validator, "mac")) {
      this.setState({ isLoading: true });
      loginByMac(this.state.mac, (data) => {
        this.setState({
          show: true,
          isLoading: false,
        });

        let audio = document.getElementById("popup");
        audio.play();
      });
      this.hideForceLoading();
    }
  }

  loginByUsername(e) {
    e.preventDefault()
    if (
      this.formValid(this.loginValidator, "email") &&
      this.formValid(this.loginValidator, "password")
    ) {
      this.setState({ isLoading: true });
      loginByEmail(this.state.email, this.state.password, (data) => {
        console.log(data);
        this.setState({ isLoading: false });
        window.localStorage.setItem("auth", "user");
        window.localStorage.setItem("token", data.message);
        window.localStorage.setItem("email", this.state.email);
        window.location.href = "#/account/devices";
      });
      this.hideForceLoading();
    }
  }

  confirmCode(e) {
    e.preventDefault();
    if (this.formValid(this.validator, "code")) {
      this.setState({ isLoading: true });
      confirmLoginCode(
        {
          mac: this.state.mac,
          code: this.state.code,
        },
        (data) => {
          this.setState({
            isLoading: false,
          });
          window.localStorage.setItem("auth", "mac");
          window.localStorage.setItem("email", this.state.email);
          localStorage.setItem("token", data.message);
          window.location.href = "#/account/devices";
        }
      );
      this.hideForceLoading();
    }
  }

  render() {
    let loading = "";
    if (this.state.isLoading) {
      loading = <Loading />;
    }
    return (
      <div className={"row"}>
        {loading}
        <PopUp
          onClose={this.onClose}
          body={
            <form onSubmit={(e) => this.confirmCode(e)}>
              <h1>Enter code</h1>
              <p>The code sent to your tv device</p>
              <div className={"form-group"}>
                <label htmlFor="username">Enter your code</label>
                <span className={"error-message"}>
                  {this.validator.message("code", this.state.code, "required")}
                </span>
                <input
                  value={this.state.code}
                  onChange={(event) => {
                    this.setState({ code: event.target.value });
                  }}
                  type="text"
                  id="code"
                  className={"form-control"}
                />
              </div>
              <div className={"form-group"}>
                <div className={"gravity-right"}>
                  <button
                    // onClick={() => this.confirmCode()}
                    className={"btn btn-success"}
                  >
                    Confirm code
                  </button>
                </div>
              </div>
            </form>
          }
          show={this.state.show}
        />
        <div className={"col-lg-6 left-block"}>
          <h3 className={"heading"}>Login by your mac address</h3>
          <form className={"form-group"} onSubmit={(e) => this.login(e)}>
            <label htmlFor="username">Enter your mac address</label>
            <span className={"error-message"}>
              {this.validator.message("mac", this.state.mac, "required")}
            </span>
            <input
              onChange={(event) => {
                this.setState({ mac: event.target.value });
              }}
              value={this.state.mac}
              type="text"
              id={"username"}
              className={"form-control"}
            />
            <div className={"gravity-right"}>
              <button
                // onClick={() => this.login()}
                className={"btn btn-success-login"}
              >
                Login by mac
              </button>
            </div>
          </form>
          <hr />
          <h3 className={"heading"}>Login by email and password</h3>
          <div className={"form-group"}>
            <label htmlFor="username">Enter your email address</label>
            <span className={"error-message"}>
              {this.loginValidator.message(
                "email",
                this.state.email,
                "required|email"
              )}
            </span>
            <input
              onChange={(event) => {
                this.setState({ email: event.target.value });
              }}
              value={this.state.email}
              type="text"
              id={"username"}
              className={"form-control"}
            />
          </div>
          <form className={"form-group"} onSubmit={(e) => this.loginByUsername(e)}>
            <label htmlFor="password">Enter your password</label>
            <span className={"error-message"}>
              {this.loginValidator.message(
                "password",
                this.state.password,
                "required"
              )}
            </span>
            <input
              onChange={(event) => {
                this.setState({ password: event.target.value });
              }}
              value={this.state.password}
              type="password"
              id={"password"}
              className={"form-control"}
            />
            <div className={"gravity-right forgot-password_box"}>
              <a className={"bottom-link"} href="#/reset">
                Forgot your password ?
              </a>
              <button
                // onClick={() => this.loginByUsername()}
                className={"btn btn-success-login"}
              >
                Login
              </button>
            </div>
          </form>

        </div>
        <div className={"col-lg-6 aside-block"}>
          <h1>Login to your account</h1>
          <p>
            You account will give you ability to manage you tv devices
            playlists, playlist groups and VOD content
          </p>
        </div>
      </div>
    );
  }
}

export default Login;