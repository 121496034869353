import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import PopUp from "./PopUp/PopUp";
import Loading from "./Loading";
import { ConfirmResellerCode, recaptcha, RegisterReseller } from "../server";
import { toast, ToastContainer } from "react-toastify";
import country from "../country.json";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
class Reseller extends Component {
  constructor(props) {
    super(props);
    this.privacyRef = React.createRef();
    this.usageRef = React.createRef();
    this.state = {
      id: 0,
      registration: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        password: "",
        country: "",
        company: "",
        address: "",
        is_resseler: true,
        is_refferal: false,
        is_privacy_accepted: false,
        is_usage_accepted: false,
      },
      acceptAll: false,
      search: [],
      code: "",
      show: false,
      isLoading: false,
    };

    this.validator = new SimpleReactValidator();
    this.onClose = this.onClose.bind(this);
  }
  componentDidMount() {
    console.log(this.props);
    console.log(country);
  }

  searchCountry(toSearch) {
    let results = [];

    for (let i = 0; i < country.length; i++) {
      for (let key in country[i]) {
        if (country[i][key].indexOf(toSearch) != -1) {
          results.push(country[i]);
        }
      }
    }

    this.setState({
      search: results,
    });
  }

  hideForceLoading() {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
  }

  onClose() {
    this.setState({
      show: false,
    });
  }
  formValid(validator, filedName) {
    if (validator.fieldValid(filedName)) {
      return true;
    } else {
      validator.showMessages();
      this.forceUpdate();
      return false;
    }
  }

  confirmCode() {
    let code = this.state.code;

    if (!code) {
      toast.error("Code required");
      return;
    }

    this.setState({
      loading: true,
    });
    ConfirmResellerCode(this.state.code, this.state.id, (data) => {
      toast.success("Confirmation success");
      this.setState({
        loading: false,
        show: false,
      });

      //   window.location.href = "https://foxapp.io/reseller/spa/#/login";
    });
  }

  chooseAllOptions() {
    this.setState({ acceptAll: !this.state.acceptAll });

    if (
      !this.state.registration.is_privacy_accepted ||
      !this.state.registration.is_usage_accepted
    ) {
      this.setState({
        registration: {
          ...this.state.registration,
          is_privacy_accepted: true,
          is_usage_accepted: true,
        },
      });
    } else {
      this.setState({
        registration: {
          ...this.state.registration,
          is_privacy_accepted: false,
          is_usage_accepted: false,
        },
      });
    }

    this.privacyRef.current.checked = !this.privacyRef.current.checked;
    this.usageRef.current.checked = !this.usageRef.current.checked;
  }

  register() {
    if (
      this.formValid(this.validator, "email") &&
      this.formValid(this.validator, "name") &&
      this.formValid(this.validator, "password") &&
      this.formValid(this.validator, "surname") &&
      this.formValid(this.validator, "phone")
    ) {
      let that = this;
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute("6LfkY5EfAAAAACWtT-qOC5mQf6WZ7OGaQXMeAwFU", {
            action: "submit",
          })
          .then((token) => {
            console.log(token);

            recaptcha({ response: token }, (data) => {
              that.setState({ loading: true });
              if (
                !that.state.registration.is_resseler &&
                !that.state.registration.is_refferal
              ) {
                toast.error("You must choose one off partner types");
                return;
              }

              RegisterReseller(
                {
                  ...that.state.registration,
                },
                (data) => {
                  console.log(data);
                  this.setState({
                    id: data.message.id,
                    loading: false,
                    // show: true,
                  });
                  window.location.href =
                    "https://foxapp.io/reseller/spa/#/login";
                }
              );

              that.hideForceLoading();
            });
          });
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  changePrivacyHandler() {
    this.state.registration.is_privacy_accepted =
      !this.state.registration.is_privacy_accepted;
    this.setState({
      registration: this.state.registration,
    });

    if (!this.state.registration.is_privacy_accepted) {
      this.setState({ acceptAll: false });
    } else {
      if (this.state.registration.is_usage_accepted) {
        this.setState({ acceptAll: true });
      }
    }
  }

  changeUsageHandler() {
    this.state.registration.is_usage_accepted =
      !this.state.registration.is_usage_accepted;
    this.setState({
      registration: this.state.registration,
    });

    if (!this.state.registration.is_usage_accepted) {
      this.setState({ acceptAll: false });
    } else {
      if (this.state.registration.is_privacy_accepted) {
        this.setState({ acceptAll: true });
      }
    }
  }

  render() {
    let loading = "";
    if (this.state.isLoading) {
      loading = <Loading />;
    }
    return (
      <div className={"row"}>
        <ToastContainer />
        {loading}
        <PopUp
          onClose={this.onClose}
          body={
            <div>
              <h1>Enter code</h1>
              <p>The code sent to your email address </p>
              <div className={"form-group"}>
                <label htmlFor="username">Enter your code</label>
                <span className={"error-message"}>
                  {this.validator.message("code", this.state.code, "required")}
                </span>
                <input
                  value={this.state.code}
                  onChange={(event) => {
                    this.setState({ code: event.target.value });
                  }}
                  type="text"
                  id="code"
                  className={"form-control"}
                />
              </div>
              <div className={"form-group"}>
                <div className={"gravity-right"}>
                  <button
                    onClick={() => this.confirmCode()}
                    className={"btn btn-success"}
                  >
                    Confirm code
                  </button>
                </div>
              </div>
            </div>
          }
          show={this.state.show}
        />

        <div className={"col-lg-6 left-block"}>
          <h3 className={"heading"}>Become a partner</h3>
          <div className={"form-group"}>
            <label htmlFor="username">Enter your company name</label>
            <span className={"error-message"}>
              {this.validator.message(
                "company",
                this.state.registration.company,
                "required"
              )}
            </span>
            <input
              autoComplete={"nope"}
              onChange={(event) => {
                this.state.registration.company = event.target.value;
                this.setState({ registration: this.state.registration });
              }}
              value={this.state.registration.company}
              type="text"
              id={"Company"}
              className={"form-control"}
            />
          </div>

          <div className={"form-group"}>
            <label htmlFor="username">
              Enter your name <span style={{ color: "red" }}>*</span>
            </label>
            <span className={"error-message"}>
              {this.validator.message(
                "name",
                this.state.registration.name,
                "required"
              )}
            </span>
            <input
              autoComplete={"nope"}
              onChange={(event) => {
                this.state.registration.name = event.target.value;
                this.setState({ registration: this.state.registration });
              }}
              value={this.state.registration.name}
              type="text"
              id={"Name"}
              className={"form-control"}
            />
          </div>
          <div className={"form-group"}>
            <label htmlFor="username">
              Enter your surname <span style={{ color: "red" }}>*</span>
            </label>
            <span className={"error-message"}>
              {this.validator.message(
                "surname",
                this.state.registration.surname,
                "required"
              )}
            </span>
            <input
              autoComplete={"nope"}
              onChange={(event) => {
                this.state.registration.surname = event.target.value;
                this.setState({ registration: this.state.registration });
              }}
              value={this.state.registration.surname}
              type="text"
              id={"Surname"}
              className={"form-control"}
            />
          </div>

          <div className={"form-group"}>
            <label htmlFor="username">Select your country</label>
            <span className={"error-message"}>
              {this.validator.message(
                "country",
                this.state.registration.country,
                "required"
              )}
            </span>
            <input
              autoComplete={"nope"}
              onInput={(event) => {
                let val = event.target.value;
                if (val.length > 0) {
                  this.searchCountry(val);
                }
              }}
              onChange={(event) => {
                this.state.registration.country = event.target.value;
                this.setState({ registration: this.state.registration });
              }}
              value={this.state.registration.country}
              type="text"
              id={"country"}
              className={"form-control"}
            />

            {this.state.search.length > 0 ? (
              <div
                style={{
                  width: "100%",
                  maxHeight: "200px",
                  background: "white",
                  height: "150px",
                  textAlign: "center",
                  overflow: "scroll",
                  overflowX: "hidden",
                  padding: "7px",
                }}
              >
                {this.state.search.map((index, key) => {
                  return (
                    <p
                      onClick={() => {
                        this.state.registration.country = index.name;
                        this.setState({
                          registration: this.state.registration,
                          search: [],
                        });
                      }}
                      className={"country_item"}
                      key={key}
                    >
                      {" "}
                      {index.code} {index.name}
                    </p>
                  );
                })}
              </div>
            ) : null}
          </div>
          <div className={"form-group"}>
            <label htmlFor="username">Enter your address</label>
            <span className={"error-message"}>
              {this.validator.message(
                "country",
                this.state.registration.address,
                "required"
              )}
            </span>
            <input
              autoComplete={"nope"}
              onChange={(event) => {
                this.state.registration.address = event.target.value;
                this.setState({ registration: this.state.registration });
              }}
              value={this.state.registration.address}
              type="text"
              id={"address"}
              className={"form-control"}
            />
          </div>

          <div className={"form-group"}>
            <label htmlFor="username">
              Enter your email <span style={{ color: "red" }}>*</span>
            </label>
            <span className={"error-message"}>
              {this.validator.message(
                "email",
                this.state.registration.email,
                "required|email"
              )}
            </span>
            <input
              autoComplete={"nope"}
              onChange={(event) => {
                this.state.registration.email = event.target.value;
                this.setState({ registration: this.state.registration });
              }}
              value={this.state.registration.email}
              type="text"
              id={"Email"}
              className={"form-control"}
            />
          </div>

          <div className={"form-group"}>
            <label htmlFor="username">Enter your phone number</label>
            <span className={"error-message"}>
              {this.validator.message(
                "phone",
                this.state.registration.phone,
                "required"
              )}
            </span>
            <PhoneInput
              placeholder="Enter phone number"
              value={this.state.registration.phone}
              onChange={(data) => {
                this.state.registration.phone = data;
                this.setState({ registration: this.state.registration });
              }}
            />
            <input
              style={{ display: "none" }}
              autoComplete={"nope"}
              value={this.state.registration.phone}
              type="number"
              id={"Phone"}
              className={"form-control"}
            />
          </div>

          <div className={"form-group"}>
            <label htmlFor="username">
              Enter your password <span style={{ color: "red" }}>*</span>
            </label>
            <span className={"error-message"}>
              {this.validator.message(
                "password",
                this.state.registration.password,
                "required"
              )}
            </span>
            <input
              autoComplete={"nope"}
              onChange={(event) => {
                this.state.registration.password = event.target.value;
                this.setState({ registration: this.state.registration });
              }}
              value={this.state.registration.password}
              type="password"
              id={"Password"}
              className={"form-control"}
            />
          </div>

          <div className={"form-group"}>
            <label htmlFor="username">
              Choose partner type <span style={{ color: "red" }}>*</span>
            </label>
            <div
              onClick={() => {
                this.state.registration.is_resseler =
                  !this.state.registration.is_resseler;
                this.setState({
                  registration: this.state.registration,
                });
              }}
            >
              <input
                type="checkbox"
                checked={this.state.registration.is_resseler}
              />
              <span> Reseller</span>
            </div>
            <div
              onClick={() => {
                this.state.registration.is_refferal =
                  !this.state.registration.is_refferal;
                this.setState({
                  registration: this.state.registration,
                });
              }}
            >
              <input
                type="checkbox"
                checked={this.state.registration.is_refferal}
              />
              <span> Referral</span>
            </div>
          </div>

          <div className={"form-group"}>
            <label htmlFor="username" onClick={() => this.chooseAllOptions()}>
              <input
                type="checkbox"
                onChange={() => this.chooseAllOptions()}
                checked={this.state.acceptAll}
              />{" "}
              Accept privacy policy and usage
              <span style={{ color: "red" }}>*</span>
            </label>
            <div onClick={() => this.changePrivacyHandler()}>
              <input
                type="checkbox"
                checked={this.state.registration.is_privacy_accepted}
                ref={this.privacyRef}
              />
              <span>
                <a href="#"> Privacy policy</a>
              </span>
            </div>
            <div onClick={() => this.changeUsageHandler()}>
              <input
                type="checkbox"
                checked={this.state.registration.is_usage_accepted}
                ref={this.usageRef}
              />
              <span>
                {" "}
                <a href="#"> Terms of usage</a>
              </span>
            </div>
          </div>
          <div className={"form-group"}>
            <div className={"gravity-right"}>
              {this.state.registration.is_privacy_accepted &&
              this.state.registration.is_usage_accepted ? (
                <button
                  onClick={() => this.register()}
                  className={"btn btn-success"}
                >
                  Register
                </button>
              ) : (
                <button className={"btn btn-success"} disabled={true}>
                  Register
                </button>
              )}
            </div>
          </div>
        </div>
        <div className={"col-lg-6 aside-block"}>
          <h1> Let’s work together</h1>
          <p>
            {" "}
            A flexible, value-based program provides you with competitive prices
            that will help you sell our app and make gains you want.
          </p>

          <h2>Partnership goals</h2>
          <p>
            Joining our program means being part of our Team. We will take care
            of all your business needs in terms of support for the app as we
            fully understand that It takes more than a great product to grow
            your business—which means we rely on our partners just as much as
            they rely on us. Whether you’re looking to create new revenue
            opportunities with existing clients, acquire new customers by
            combining our app with your playlist sale for an all in one package,
            or all of the above, we’ve got the tools you need to make it happen.
          </p>

          <h2>Find the right fit for you</h2>
          <p>
            No two businesses are the same, which is why our program is based on
            capabilities and contributions. Get your business in shape by
            choosing the option that works for you—we’ll spot you from there.
          </p>

          <h3>Reseller program</h3>
          <p>
            You will be able to purchase the app at a competitive price which
            will go lower as you advance in tears and are able to sell it at the
            price that you want. You also benefit from support to your customers
            and have full control over the devices you activate.
          </p>

          <h3>Affiliate program</h3>
          <p>
            Everything you need to get up and running without an initial
            investment. With this program we would like to give the opportunity
            for our partners to start with no initial investment. You’ll be able
            to generate links that you could set for different acquisition
            platforms and track your stats as well have a revenue stream just by
            focusing on marketing.
          </p>
        </div>
      </div>
    );
  }
}

export default Reseller;
