import React, {Component} from 'react';
class Footer extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div></div>
            // <footer>
            //     <p>Copyright 2020-{new Date().getFullYear()} &copy;</p>
            // </footer>
        )
    }
}

export default Footer;