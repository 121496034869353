import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ItemsList from "./common/ItemsList";
import PopUp from "./PopUp/PopUp";
import {
  add_external_epg_list,
  get_external_epg_list,
  remove_external_epg_link,
} from "../server";

export default () => {
  const [url, setUrl] = useState("");
  const [epgItems, setEpgItems] = useState([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [activeId, setActiveId] = useState();

  const changeUrl = (e) => {
    setUrl(e.target.value);
  };

  const addEpgHandler = (e) => {
    e.preventDefault();

    add_external_epg_list(
      { url },
      () => {
        toast.success("EPG added successfully");
        setUrl("");
        getEpgItems();
      },
      (err) => {
        toast.error(err);
      }
    );
  };

  const getEpgItems = () => {
    get_external_epg_list(
      (data) => {
        setEpgItems(data.message);
      },
      (err) => {
        toast.error(err);
      }
    );
  };

  const removeEpgLinkHandler = (id) => {
    setIsConfirmationModalOpen(true);
    setActiveId(id);
  };

  function getQueryParams(qs) {
    qs = qs.split("+").join(" ");
    var params = {},
      tokens,
      re = /[?&]?([^=]+)=([^&]*)/g;
    while ((tokens = re.exec(qs))) {
      params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }
    return params;
  }

  useEffect(() => {
    const location_hash = window.location.hash;

    const token = location_hash.split("?")[1];
    if (!token) {
      window.location = "/";
    }
    const includesToken = getQueryParams(token);

    if (!includesToken.token) {
      window.location = "/";
    } else {
      getEpgItems();
      localStorage.setItem("token", includesToken.token);
    }
  }, []);

  const removeItem = () => {
    remove_external_epg_link(
      { id: activeId },
      () => {
        getEpgItems();
        setIsConfirmationModalOpen(false);
      },
      (err) => {
        toast.error(err);
      }
    );
  };

  useEffect(() => {}, [isConfirmationModalOpen]);

  return (
    <div>
      <PopUp
        onClose={() => setIsConfirmationModalOpen(false)}
        show={isConfirmationModalOpen}
        body={
          <>
            <h3 className="are-you_sure">
              Are you sure you want to remove this EPG?
            </h3>
            <div className="remove-epg_actions">
              <button className="btn btn-success w-25" onClick={removeItem}>
                Ok
              </button>
              <button
                className="btn btn-outline-primary w-25 ml-2"
                onClick={() => setIsConfirmationModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </>
        }
      ></PopUp>
      <h2>Add new epg</h2>
      <form onSubmit={addEpgHandler}>
        <label htmlFor="url">
          <p className="epg-url_label">Enter Url</p>{" "}
          <input
            type="text"
            id="url"
            value={url}
            onChange={changeUrl}
            className="add-epg_input"
          />
        </label>

        <button className="btn btn-success d-flex submit-button_epg">
          Add Epg
        </button>
      </form>

      {epgItems.length ? (
        <ItemsList data={epgItems} remove={removeEpgLinkHandler} />
      ) : (
        <p className="no-epg_label">No epg added</p>
      )}
    </div>
  );
};
