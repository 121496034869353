import React, {Component} from 'react';
import Header from './Header'
import Footer from './Footer'
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import CookieAccept from "./CookieAccept";
class Body extends Component{
    constructor(props) {
        super(props);
    }

    render(){
        let header = <Header/>;
        return(
            <div>
                <CookieAccept/>
                <ToastContainer/>
                {header}
                <div className={"container"}>
                    {this.props.children}
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Body;
