import React from "react";
import errIMg from "../img/err.png";
import {dateFormat} from '../utils/utils';

export default function NewsItem({ title, description, image, id,createdAt }) {
  const onClick = () => {
    window.location.href = `#/news/${id}`;
  };


  return (
    <div className="news-page__card" onClick={onClick}>
      <img
        className="news-page__card-top"
        src={image}
        alt=""
        onError={(e) => (e.target.src = errIMg)}
      />
      <div className="news-page__card-bottom">
        <p className="news-page__title">{title}</p>
        <p className="news-card__createdAt">Added on: {dateFormat(createdAt)} </p>

        <div className="news-page__desc">
          {description && description.length > 100
            ? description.slice(0, 100) + "..."
            : description}
        </div>
      </div>
    </div>
  );
}
