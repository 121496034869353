import React, { useState } from "react";
import { toast } from "react-toastify";
import { voucherCodeActivation } from "../server";

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [voucher_mac, setVoucherMac] = useState("");
  const [voucher_code, setVoucherCode] = useState("");

  const activate_by_voucher_code = () => {
    setIsLoading(true);

    voucherCodeActivation(voucher_code, voucher_mac, (data) => {
      toast.success("Device activated successfully");
      setIsLoading(false);
      setVoucherMac("");
      setVoucherCode("");
    });
  };

  return (
    <div className="w-75 m-auto">
      <h1>Voucher code activation for Samsung and Lg Qa team</h1>
      <div className={"form-group"}>
        <label htmlFor="username">Enter your mac</label>
        <input
          value={voucher_mac}
          onChange={(event) => {
            setVoucherMac(event.target.value);
          }}
          type="text"
          id="code"
          className={"form-control"}
        />
      </div>
      <div className={"form-group"}>
        <label htmlFor="username">Enter voucher code</label>
        <input
          value={voucher_code}
          onChange={(event) => {
            setVoucherCode(event.target.value);
          }}
          type="text"
          id="code"
          className={"form-control"}
        />
      </div>
      <div className={"form-group"}>
        <div className={"gravity-right"}>
          <button
            onClick={activate_by_voucher_code}
            className={"btn btn-success"}
          >
            Activate
          </button>
        </div>
      </div>
    </div>
  );
};
