import React, { Component } from "react";
import { getNews } from "../server";
import Logo from "../img/menu_logo.png";
class Header extends Component {
  constructor(props) {
    super(props);

    this.state = { menu: [] };

    this.menu = [
      { href: "#/payment", name: "Activation" },
      { href: "#/news", name: "News" },
      { href: "#/help", name: "Help" },

      {
        href: "https://reseller.foxapp.io",
        name: "Reseller Panel",
      },
      { href: "#/reseller", name: "Become a reseller" },
      { href: "#/login", name: "Login" },
      { href: "#/registration", name: "Registration" },
    ];

    this.menuLoggedIn = [
      { href: "#/account/devices", name: "Devices" },
      { href: "#/account/logout", name: "Logout" },
    ];
  }

  componentDidMount() {
    if (this.props.loggedIn) {
      this.setState({ menu: this.menuLoggedIn });
    } else {
      this.setState({ menu: this.menu });
    }
    getNews(
      (data) => {
        if (!data.error) {
          if (data.message.rows.length > 0) {
            localStorage.setItem("hasNews", true);
          } else {
            localStorage.setItem("hasNews", false);
          }
        }
      },
      {
        limit: 10,
      }
    );
  }

  render() {
    return (
      <header>
        <div className={"row"}>
          <div className={"col-lg-4"}>
            <img className={"logo"} src={Logo} alt="" />
            {this.props.loggedIn}
          </div>
          <div className={"col-lg-8"}>
            <ul>
              {this.state.menu.map((index, key) => {
                if (
                  index.name === "News" &&
                  localStorage.getItem("hasNews") === "false"
                ) {
                  return;
                }
                return (
                  <li key={key}>
                    <a
                      onClick={() => {
                        if (index.href.indexOf("logout") !== -1) {
                          window.location.href = index.href;
                          window.location.reload();
                        } else if (index.href.indexOf("devices") !== -1) {
                          window.location.href = index.href;
                          window.location.reload();
                        } else {
                          window.location.href = index.href;
                        }
                      }}
                    >
                      {index.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
