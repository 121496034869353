import React, {Component} from 'react';
class Help extends Component{
    constructor(props) {
        super(props);
    }

    render() {

        return(
                <div className={"help"}>
                    <h1 className={"heading"}>Login to your account</h1>
                    <p>Download on your tv <span className={"foxiptv"}>FOXIPTV</span> app and run it. Follow activation link for activating your tv device</p>
                    <a className={"btn btn-success"} href="#/payment">Activation Link</a>
                    <hr/>

                    <h1 >For more help contact us : <a href="mailto:support@foxapp.io">support@foxapp.io</a></h1>
                </div>
        )
    }
}

export default Help;