import React, { useEffect, useState, useRef } from "react";
import { getNews } from "../server";
import NewsItem from "./NewsItem";
import "./news.css";

export default function News() {
  const [news, setNews] = useState([]);
  const [count, setCount] = useState(10);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const observer = useRef();
  const getNewsData = () => {
    setIsLoading(true);
    getNews(
      (data) => {
        setIsLoading(false);
        
        if (!data.error) {
          setCount(data.message.count);
          setNews(data.message.rows);
          if(data.message.rows.length>0){
            localStorage.setItem("hasNews",true)
          }else{
            localStorage.setItem("hasNews",false)
          }
        }
      },
      {
        limit,
      }
    );
  };

  useEffect(() => {
    if (count > limit || count === limit) {
      getNewsData();
    }
  }, [limit]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setLimit((limit) => limit + 1);
        }
      },
      { threshold: 1 }
    );
  }, []);

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current.observe(document.querySelector(".bottom"));
  }, [news]);

  return (
    <div className="news-page">
      <div className="news-page-wrapper">
        {news.map((item) => (
          <NewsItem {...item} key={item.id} />
        ))}
         {isLoading && <div>Loading...</div>}
        <div className="bottom"></div>
      </div>
    </div>
  );
}
