import React from 'react';
import Header from './Header'
import Footer from './Footer'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import { validateToken } from "../server";
import CookieAccept from "./CookieAccept";

class AccountBody extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            isLoggedIn: false
        }
        this.form = "";
    }

    getQuery () {

        console.log(window.location);

        let params = window.location.href.split("?")[1];

        var query = {};

        if (params) {

            params = params.split("&");

            for (var i = 0; i < params.length; i++) {

                var param = params[i].split("=");

                query[param[0]] = param[1];

            }

        }

        return query;

    }

    componentDidMount () {

        let query = this.getQuery();

        if (query.token) localStorage.setItem("token", query.token);

        validateToken(() => {

            this.setState({
                isLoggedIn: true,
                page: <div>
                    <ToastContainer />
                    <CookieAccept />
                    <Header loggedIn={ true } />
                    <div className={ "container" }>{ this.props.children }</div>
                    <Footer />
                </div>
            })

        }, () => {
            // window.location.href = "#/login"
        })
    }

    render () {
        return (
            <div>
                { this.state.page }
            </div>
        );
    }
}

export default AccountBody;
