const CONFIG = {
  API: "https://api.foxapp.io/api",
  API_V2: "https://api.foxapp.io/api/v2",
  // API : "https://dev.foxapp.io:8000/api",
  RESSELER_API: "https://api.foxapp.io",
  ROUTES: {
    PARSE_PLAYLIST: "/parse_playlist",
    LOGIN_MAC: "/login_by_mac",
    CONFIRM_LOGIN: "/confirm_login_by_mac",
    LOGIN_EMAIL: "/login",
    MAKE_PAYMENT: "/payment",
    DEVICE: "/devices",
    DEVICE_ADD: "/device",
    CONFIRM_DEVICE: "/confirm_device",
    ADD_PLAYLIST: "/playlist",
    EDIT_PLAYLIST: "/playlist",
    REGISTER: "/register",
    INFO: "/app_info",
    PAY: "/payment",
    PARSE: "/parse_playlist",
    VALIDATE: "/validate",
    PAYPAL: "/paypal/payment",
    IP: "/getIp",
    VOUCHER_CODE: "/voucher_code_activation",
    VALIDATE_MAC: "/validate_mac",
    RESSELLER: "/resseler/register",
    RESSELLER_CONFIRM: "/resseler/check_code",
    REFFERAL_ACTIVATION: "/resseler/activate_by_link",
    ACTIVATION_COUPON: "/coupon_activation",
    NEWS:"/news",
    NEWS_NOTE_IN:"/news_notIn",
  },
};

export default CONFIG;
