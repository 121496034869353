export const parseDate = (data) => {
    let d = new Date(data);
    let year = d.getFullYear();
    let hours = d.getHours();
    let month = d.getMonth() + 1;
    let minutes = d.getMinutes();
    let day = d.getDate();

    if (day < 10){ day = "0"+day}
    if (minutes < 10){minutes = "0"+minutes}
    if (month < 10){month = "0"+month}
    if (hours < 10){hours = "0"+hours};

    return month+"/"+day+"/"+year + " " + hours+":"+minutes;
}



export function dateFormat(date) {
    const dateObj = new Date(date);
  
    let year = dateObj.getFullYear();
    let day = dateObj.getDate();
    let month = dateObj.getMonth();
  
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;
  
    return `${day}/${month}/${year}`;
  }