import React, { Component } from "react";
import devices from "../data/devices.json";
// import { NavLink } from "react-router-dom";
import { Link } from "react-router";
import plicon from "../img/plicon.jpg";
import {
  addDevice,
  confirmDeviceCode,
  getDevice,
  getDevices,
  editPlaylist,
  removePlaylist,
} from "../server";
import LG from "../img/lg.png";
import Tizen from "../img/tizen.png";
import PopUp from "./PopUp/PopUp";
import SimpleReactValidator from "simple-react-validator";
import Loading from "./Loading";

class Devices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: -1,
      devices: [],
      show: false,
      codeFetched: false,
      code: "",
      mac: "",
      isLoading: false,
      isMacAuth: false,
      fetched: false,
      editableItemIndex: -1,
      editedDeviceName: "",
      editedDeviceUrl: "",
      isDeviceModalVisible: false,
    };

    this.validator = new SimpleReactValidator();
    this.codeValidtor = new SimpleReactValidator();
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.setState({ show: false, codeFetched: false });
  }

  hideForceLoading() {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
  }
  findDevices() {
    let auth = window.localStorage.getItem("auth");
    this.setState({ isLoading: true });
    if (auth === "mac") {
      getDevice((data) => {
        this.state.devices = [];
        this.state.devices.push(data.message);
        this.setState({
          isMacAuth: true,
          devices: this.state.devices,
        });
      });
    } else {
      getDevices((data) => {
        this.setState({ devices: data.message }, () => {
          this.setState({ isLoading: false, fetched: true });
        });
      });
    }

    this.hideForceLoading();
  }
  componentDidMount() {
    this.findDevices();
  }

  open(key) {
    if (key === this.state.key) {
      this.setState({ key: -1 });
    } else {
      this.setState({ key });
    }
  }
  openManager(id, deviceIndex, playlistIndex, name) {
    window.location.href =
      "#/account/manage?id=" +
      id +
      "+deviceIndex=" +
      deviceIndex +
      "&playlistIndex=" +
      playlistIndex +
      "&name=" +
      name;
    window.location.reload();
  }
  formValid(validator, filedName) {
    if (validator.fieldValid(filedName)) {
      return true;
    } else {
      validator.showMessages();
      this.forceUpdate();
      return false;
    }
  }

  removePlaylist(id) {
    this.setState({ isLoading: true });
    removePlaylist(id, (data) => {
      this.findDevices();
    });
    this.hideForceLoading();
  }
  addDevice(e) {
    e.preventDefault();
    if (this.formValid(this.validator, "mac")) {
      this.setState({ isLoading: true });
      addDevice(this.state.mac, (data) => {
        this.setState({
          isLoading: false,
          codeFetched: true,
        });
      });
      this.hideForceLoading();
    }
  }
  confirmCode(e) {
    e.preventDefault();
    if (
      this.formValid(this.validator, "mac") &&
      this.formValid(this.codeValidtor, "code")
    ) {
      this.setState({ isLoading: true });
      confirmDeviceCode({ code: this.state.code, mac: this.state.mac }, () => {
        this.findDevices();
        this.onClose();
      });
      this.hideForceLoading();
    }
  }

  closeDeviceModal() {
    this.setState({ isDeviceModalVisible: false });
  }

  toggleDeviceItemState(id, index) {
    this.setState({ isDeviceModalVisible: true });
    this.setState({ editableItemIndex: index });

    this.setState({
      editedDeviceName: this.state.devices[0].playlists[index].name,
      editedDeviceUrl: this.state.devices[0].playlists[index].url,
    });
  }

  editeDeviceNameHandler(e) {
    this.setState({ editedDeviceName: e.target.value });
  }

  editeDeviceUrlHandler(e) {
    this.setState({ editedDeviceUrl: e.target.value });
  }

  saveDevicePlaylistChanges() {
    this.setState({ isLoading: true });

    editPlaylist(
      {
        id: this.state.devices[0].playlists[this.state.editableItemIndex].id,
        name: this.state.editedDeviceName,
        url: this.state.editedDeviceUrl,
      },

      (data) => {
        this.findDevices();
        this.closeDeviceModal();
      }
    );
  }

  render() {
    let loading = "";
    let addBtn = "";
    if (this.state.isLoading) loading = <Loading />;
    if (!this.state.isMacAuth && this.state.fetched) {
      addBtn = (
        <div style={{ textAlign: "right" }} className={"col-md-6"}>
          <button
            onClick={() => {
              this.setState({ show: true });
            }}
            className={"btn btn-success"}
          >
            Add new device
          </button>
        </div>
      );
    }
    return (
      <div className="devices-main">
        {loading}
        <div className={"row"}>
          <div className={"col-md-6"}>
            <h1 className={"heading"}>Devices</h1>
          </div>
          {addBtn}
        </div>
        <PopUp
          onClose={this.onClose}
          body={
            <div className="popup_wrapper">
              <h2>Enter device mac and activate it</h2>
              <p>
                Make sure that you have already downloaded application to you
                device
              </p>
              <form className={"row inputs_field"} onSubmit={(e) => this.addDevice(e)}>
                <div className={"col-md-10"}>
                  <div className={"form-group"}>
                    <label htmlFor="username">Enter your mac address</label>
                    <span className={"error-message"}>
                      {this.validator.message(
                        "mac",
                        this.state.mac,
                        "required"
                      )}
                    </span>
                    <input
                      value={this.state.mac}
                      onChange={(event) => {
                        this.setState({ mac: event.target.value });
                      }}
                      type="text"
                      id="code"
                      className={"form-control"}
                    />

                  </div>
                </div>
                <div className={"col-md-2"}>
                  <div className={"form-group"}>
                    <button
                      // onClick={ () => this.addDevice() }
                      className={"btn btn-success rightInputBtn"}
                    >
                      Add device
                    </button>
                  </div>
                </div>
              </form>
              {this.state.codeFetched ? (
                <form className={"row inputs_field"} onSubmit={(e) => this.confirmCode(e)}>
                  <div className={"col-md-10"}>
                    <p className={"alert alert-primary"}>
                      The code sent to your tv device
                    </p>
                    <div className={"form-group"}>
                      <label htmlFor="username">Enter your code</label>
                      <span className={"error-message"}>
                        {this.codeValidtor.message(
                          "code",
                          this.state.code,
                          "required"
                        )}
                      </span>
                      <input
                        value={this.state.code}
                        onChange={(event) => {
                          this.setState({ code: event.target.value });
                        }}
                        type="text"
                        id="code"
                        className={"form-control"}
                      />

                    </div>
                  </div>
                  <div className={"col-md-2"}>
                    <div className={"form-group"}>
                      <div className={"gravity-right"}>
                        <button
                          className={"btn btn-success rightInputBtn"}
                        >
                          Confirm code
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                ""
              )}
            </div>
          }
          show={this.state.show}
        />

        <PopUp
          onClose={() => this.closeDeviceModal()}
          body={
            <div>
              <h2>Edit Device Player</h2>
              <div className={"row"}>
                <div className={"col-md-12"}>
                  <div className={"form-group"}>
                    <label htmlFor="name">Name</label>

                    <input
                      value={this.state.editedDeviceName}
                      onChange={(e) => this.editeDeviceNameHandler(e)}
                      type="text"
                      id="name"
                      className={"form-control"}
                    />
                  </div>
                </div>

                <div className={"col-md-12"}>
                  <div className={"form-group"}>
                    <label htmlFor="url">Url</label>

                    <input
                      value={this.state.editedDeviceUrl}
                      onChange={(e) => this.editeDeviceUrlHandler(e)}
                      type="text"
                      id="url"
                      className={"form-control"}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <button
                  onClick={() => this.saveDevicePlaylistChanges()}
                  className={"btn btn-success mr-2"}
                >
                  Save
                </button>
                <button
                  className="btn btn-secondary mr-3"
                  onClick={() => this.closeDeviceModal()}
                >
                  Cancel
                </button>
              </div>
            </div>
          }
          show={this.state.isDeviceModalVisible}
        />

        {this.state.devices.length ? (
          this.state.devices.map((index, key) => {
            let block;
            let opener = (
              <i
                onClick={ () => this.open(key) }
                className={ "fa fa-arrow-down mt-4 mobile-arrow-down" }
              ></i>
            );
            if (this.state.key === key) {
              block = (
                <div className={"col-md-12 more-playlists"}>
                  <div className={"row"}>
                    <div className={"col-md-6"}>
                      <h2>Plyalists</h2>
                    </div>
                    <div className={"col-md-6"}>
                      <div className={"gravity-right"}>
                        <a
                          onClick={() => {
                            window.location.href =
                              "#/account/addpllaylist?type=url&mac=" +
                              index.mac +
                              "&name=" +
                              index.model +
                              "&id=" +
                              index.id;
                            window.location.reload();
                          } }
                          style={ { color: "white", display: "inline-block" } }
                          className={ "btn btn-success new-playlist-btn" }
                        >
                          Add new playlist
                        </a>
                      </div>
                    </div>
                  </div>
                  {index.playlists.map((item, index) => {
                    return (
                      <li key={item.id} className="list-group-item mt-2">
                        <div className="d-flex align-items-center justify-content-between">
                          <span
                            className="text-truncate"
                            style={{ "max-width": "200px" }}
                          >
                            Name:{" "}
                            <span className="text-muted text-truncate">
                              {item.name}
                            </span>
                          </span>
                          <span
                            className="text-truncate"
                            style={{ "max-width": "75%" }}
                          >
                            Url:
                            <span className="text-muted">{item.url}</span>
                          </span>

                          <div className="d-flex align-items-center">
                            <i
                              className="fa fa-edit text-primary d-block text-right btn"
                              onClick={() =>
                                this.toggleDeviceItemState(item.id, index)
                              }
                            ></i>{" "}
                            <i
                              onClick={() => this.removePlaylist(item.id)}
                              className={
                                "fa fa-trash text-danger remove-playlist_icon"
                              }
                            />
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </div>
              );

              opener = (
                <i
                  onClick={() => this.open(key)}
                  className={"fa fa-arrow-up mt-4"}
                ></i>
              );
            }
            return (
              <div key={ key } className={ "row device-item" }>
                <div className={ "col-md-3" }>
                  { index.model.toLowerCase() === "tizen" ? (
                    <img style={ { width: "50%" } } src={ Tizen } alt="" className="device-logo" />
                  ) : (
                    <img style={ { width: "50%" } } src={ LG } alt="" className="device-logo" />
                  ) }
                </div>
                <div className={"col-md-6"}>
                  <p>Name : {index.model}</p>
                  <p>Playlists : {index.playlists.length || 0}</p>
                  <p>Mac : {index.mac}</p>
                </div>
                <div className={"col-md-3 open-block"}>
                  {opener}

                  {!index.payed ? (
                    <a
                      style={{
                        position: "absolute",
                        right: 0,
                      } }
                      className={ "btn btn-danger activate-btn" }
                      href={ "#/payment?mac=" + index.mac }
                    >
                      Activate your device
                    </a>
                  ) : null}
                </div>

                {block}
              </div>
            );
          })
        ) : (
          <div
            style={{ textAlign: "center", marginTop: "20px" }}
            className={"device"}
          >
            <h2>No devices found</h2>
            <h4>Hit add new device to add device</h4>
          </div>
        )}
      </div>
    );
  }
}

export default Devices;
