import React, { useEffect, useState } from "react";
import { getNews, getRelatedNews } from "../server";

import NewsItem from "./NewsItem";
import errImg from "../img/err.png";
import { dateFormat } from "../utils/utils";
import "./news.css";

export default function CurrentNews() {
  const [currentNews, setCurrentNews] = useState({});
  const [news, setNews] = useState([]);
  const [id, setId] = useState(
    window.location.hash.substring(window.location.hash.lastIndexOf("/") + 1)
  );
  const getCurrent = () => {
    const params = {
      filter: id,
    };
    getNews((data) => {
      setCurrentNews(data.message.rows[0]);
    }, params);
  };
  const getRelated = () => {
    getRelatedNews({ ids: `[${id}]` }, (data) => {
      setNews(data.message);
    });
  };

  useEffect(() => {
    getCurrent();
    getRelated();
  }, [id]);

  useEffect(() => {
    setId(
      window.location.hash.substring(window.location.hash.lastIndexOf("/") + 1)
    );
  }, [window.location.hash]);

  return (currentNews &&
    <div className="current-news-page">
      <div className="current-news-page__top">
        <div
          className="current-news-page__back-btn"
          onClick={() => {
            window.history.back();
          }}
        >
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.5338 18.5554L0.511719 9.53331L9.5338 0.51123L11.1409 2.09009L4.8254 8.40555H18.5559V10.6611H4.8254L11.1409 16.9765L9.5338 18.5554Z"
              fill="black"
            />
          </svg>
        </div>

        <div className="current-news-page__current-news">
          <img
            src={currentNews.image}
            alt=""
            className="current-news-page__current-news__img"
            onError={(e) => (e.target.src = errImg)}
          />
          <div>
            <p className="current-news-page__current-news__title">
              {currentNews.title}
            </p>
            <p className="news-card__createdAt">Added on: {dateFormat(currentNews.createdAt)} </p>

            <span className="current-news-page__current-news__description">
              {currentNews.description}
            </span>
          </div>
        </div>
      </div>

      <div className="current-news-page__similar-news">
        <p className="current-news-page__similar-news-title">Similar news</p>
        <div className="current-news-page__bottom">
          {news && news.slice(0,3).map((item) => <NewsItem {...item} key={item.id} />)}
        </div>
      </div>
    </div>
  );
}
