import React, { Component } from 'react';
import SimpleReactValidator from "simple-react-validator";
import {
    activateReferralDevice,
    getAppInfo,
    getIp,
    payment,
    paypalPayment,
    validateMacAddress,
    voucherCodeActivation
} from "../server";
import Loading from "./Loading";
import cmi from '../img/cmi.jpeg'
import visamaster from '../img/visamaster.png';
import paypalLogo from '../img/paypall.png';
import Axios from "axios";
import { toast } from "react-toastify";
import PopUp from "./PopUp/PopUp";
class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            priceMad: "-",
            price: 0,
            priceEuro: "-",
            macIsValid: "",
            isLoading: true,
            mac: "",
            isAllChecked: false,
            isPrivacyAccepted: false,
            isTermsAccepted: false,
            isSalesAccepted: false,
            payWithPeyPall: true,
            voucher_code: "",
            voucher_code_activation_popup: false,
            voucher_mac: "",
        }

        this.codeValidtor = new SimpleReactValidator();
        this.onClose = this.onClose.bind(this);

    }
    hideForceLoading () {
        setTimeout(() => {
            this.setState({ isLoading: false })
        }, 2000);
    }
    activate_by_voucher_code (e) {
        e.preventDefault();

        //A751839

        this.setState({
            loading: true
        })

        voucherCodeActivation(this.state.voucher_code, this.state.voucher_mac, (data) => {
            toast.success("Device activated with success");
            this.setState({
                loading: false,
                voucher_code_activation_popup: false
            })
        })

        this.hideForceLoading();
    }

    activateReferral (cb) {
        let referral = this.getReferral();
        if (referral.code && referral.reseller_id) {
            console.log("ACTIVATE")
            activateReferralDevice(referral.code, this.state.mac, referral.reseller_id, (data) => {
                console.log(data)
                cb()
            })
        } else {
            console.log("NO REFERRAL")
            cb()
        }
    }

    handlePayment (details) {
        let id = details.purchase_units[0].payments.captures[0].id
        this.activateReferral(() => {
            paypalPayment(this.state.mac, this.state.price, this.state.email, id, JSON.stringify(details), (data) => {
                window.location.href = "#/ok"
                this.setState({ isLoading: false })
            }, (err) => {
                window.location.href = "#/fail"
                this.setState({ isLoading: false })
            })
        })

    }

    getReferral () {
        return this.props.location.query;
    }
    componentDidMount () {
        this.getClientIp();
        getAppInfo(data => this.setState({
            priceMad: data.message.price + " MAD",
            priceEuro: (data.message.price / 10).toFixed() + " EUR",
            price: (data.message.price / 10)
        }, () => {
            window.paypal.Buttons({
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                value: this.state.price,
                            },
                            description: this.state.mac,
                        }]
                    });
                },
                onApprove: (data, actions) => {
                    return actions.order.capture().then((details) => {
                        this.handlePayment(details);
                    });
                },
                onError: function (err) {
                    this.setState({ isLoading: false })
                    toast.error(err.message);
                }
            }).render("#buttons-paypal");
        }))

        let mac = this.props.location.query.mac;
        if (mac && mac.length > 0) {
            this.setState({ mac: mac, macIsValid: true })

        }

        let email = window.localStorage.getItem("email");
        if (email && email.length) {
            this.setState({
                email: email
            })
        }

    }
    getClientIp () {
        getIp((data) => this.checkPaymentMethod(data.message))
    }

    formValid () {
        if (this.codeValidtor.allValid()) {
            return true;
        } else {
            this.codeValidtor.showMessages();
            this.forceUpdate();
            return false;
        }
    }

    checkPaymentMethod (ip) {
        let url = "https://ipapi.co/" + ip + "/json";
        Axios.get(url).then((data) => {
            let countryCode = "MA";
            let ipCountryCode = data.data.country_code;
            if (ipCountryCode === countryCode) {
                this.setState({ payWithPeyPall: false, isLoading: false })
            } else {
                this.setState({ payWithPeyPall: true, isLoading: false })
            }
        }).catch((err) => {
            this.setState({ payWithPeyPall: false, isLoading: false })
        })
    }
    pay () {
        if (this.formValid()) {
            this.setState({ isLoading: true })

            payment({
                mac: this.state.mac,
                email: this.state.email || "no email address",
                BillTocompany: 'FoxIptv',
                BillToName: this.state.mac,
                callbackUrl: encodeURI('https://foxapp.io:80/api/callback'),
                clientId: "600002144", //LIVE CLIENT ID 600002115 //TEST CLIENT ID "600001220"
                currency: '504',
                failUrl: 'https://foxapp.io:80/api/payment_error',
                hashAlgorithm: 'ver3',
                lang: 'en',
                okurl: 'https://foxapp.io:80/api/payment_success',
                rnd: new Date().getTime() / 1000,
                storetype: '3d_pay_hosting',
                TranType: 'PreAuth',
            }, (data) => {
                for (let key in data.data) {
                    let input = document.createElement("input");
                    input.type = "hidden";
                    input.value = data.data[key];
                    input.name = key
                    document.getElementById("form").appendChild(input)
                }

                document.getElementById("form").submit();
            })

            this.hideForceLoading();
        }
    }

    onClose () {
        this.setState({
            voucher_code_activation_popup: false
        })
    }
    render () {
        let displayButtons = "none";
        let loading = "";
        if (this.state.isLoading) loading = <Loading />
        if (this.state.isPrivacyAccepted && this.state.isTermsAccepted && this.state.isSalesAccepted && this.state.macIsValid === true) {
            displayButtons = "block";
        }
        return (
            <div className={"row"}>
                <PopUp
                    onClose={this.onClose}
                    body={
                        <form onSubmit={(e) => this.activate_by_voucher_code(e)}>
                            <h1>Voucher code activation for Samsung and Lg Qa team</h1>
                            <div className={"form-group"}>
                                <label htmlFor="username">Enter your mac</label>
                                <input value={this.state.voucher_mac} onChange={(event) => {
                                    this.setState({ voucher_mac: event.target.value })
                                }} type="text" id="code" className={"form-control"} />
                            </div>
                            <div className={"form-group"}>
                                <label htmlFor="username">Enter voucher code</label>
                                <input value={this.state.voucher_code} onChange={(event) => {
                                    this.setState({ voucher_code: event.target.value })
                                }} type="text" id="code" className={"form-control"} />
                            </div>
                            <div className={"form-group"}>
                                <div className={"gravity-right"}>
                                    <button
                                        // onClick={() => this.activate_by_voucher_code()} 
                                        className={"btn btn-success"}
                                    >
                                        Activate
                                    </button>
                                </div>
                            </div>
                        </form>
                    } show={this.state.voucher_code_activation_popup} />
                {loading}
                <form className={"col-lg-6 left-block"} onSubmit={(e) => {
                    e.preventDefault();
                    this.setState({
                        // voucher_code_activation_popup: true
                    })
                }}>
                    <form id={"form"} method={"post"} action="https://payment.cmi.co.ma/fim/est3Dgate">
                        {/*<form id={"form"} method={"post"} action="https://testpayment.cmi.co.ma/fim/est3Dgate">*/}

                    </form>
                    <div className={"form-group"}>
                        <label id={"email"} htmlFor="card-number">Enter your email address</label>
                        <span className={"error-message"}>{this.codeValidtor.message('email', this.state.email, 'required|email')}</span>
                        <input onChange={(event) => {
                            this.setState({ email: event.target.value })
                        }} value={this.state.email} placeholder={"ex@domain.tld"} type="text" id={"email"} className={"form-control"} />
                    </div>
                    <div className={"form-group"}>
                        <label id={"mac"} htmlFor="card-number">Enter your mac address</label>
                        <span className={"error-message"}>{this.codeValidtor.message('mac', this.state.mac, 'required')}</span>
                        <input onChange={(event) => {
                            this.setState({
                                mac: event.target.value
                            })
                            validateMacAddress(event.target.value, (isValid) => {
                                console.log(isValid);
                                this.setState({ macIsValid: true })
                            }, () => {
                                this.setState({ macIsValid: false })
                            })

                        }} value={this.state.mac} placeholder={"CC-CC-CC-CC"} type="text" id={"mac"} className={"form-control"} />
                        {this.state.macIsValid === false && this.state.mac !== "" ? <span style={{ color: "red" }}>Mac address is not valid </span>
                            : this.state.macIsValid && this.state.mac !== "" ? <span style={{ color: "green" }}>Mac address is valid</span> : null}
                    </div>
                    {this.state.payWithPeyPall ? <div>
                        <img style={{ width: "40px" }} src={paypalLogo} alt="CMI" />
                        <div id="buttons-paypal" style={{ display: displayButtons }}></div>
                    </div> :
                        <div>
                            <div className={"form-group row"}>
                                <div className={"col-md-7"}>
                                    {this.state.isPrivacyAccepted && this.state.isTermsAccepted && this.state.isSalesAccepted ?
                                        <div>
                                            <div>
                                                <img style={{ width: "40px" }} src={cmi} alt="CMI" />
                                                <img style={{ width: "110px" }} src={visamaster} alt="VISA MASTER" />
                                            </div>
                                        </div>
                                        : ""}
                                </div>
                                <div className={"col-md-5"}>
                                    <div className={"form-group"}>

                                        <div className={"gravity-right"}>
                                            {this.state.isPrivacyAccepted && this.state.isTermsAccepted && this.state.isSalesAccepted ?
                                                <button onClick={() => this.pay()} className={"btn btn-success"}>Pay</button>
                                                :
                                                <button disabled={true} className={"btn btn-default"}>Pay</button>

                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>}

                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <div className={"form-group"}>
                                <div>
                                    <input id={"all"} type="checkbox" onClick={() => {
                                        this.setState({
                                            isAllChecked: !this.state.isAllChecked,
                                            isPrivacyAccepted: !this.state.isAllChecked,
                                            isTermsAccepted: !this.state.isAllChecked,
                                            isSalesAccepted: !this.state.isAllChecked,
                                        })
                                    }} checked={this.state.isAllChecked} value={this.state.isAllChecked} />
                                    <a style={{
                                        display: "inline-block",
                                        marginLeft: "10px",
                                        fontSize: "21px",
                                    }}><label htmlFor="all">Agree with all</label></a>
                                </div>
                                <div>
                                    <input type="checkbox" onClick={() => {
                                        this.setState({
                                            isPrivacyAccepted: !this.state.isPrivacyAccepted,
                                        })
                                    }} checked={this.state.isPrivacyAccepted} value={this.state.isPrivacyAccepted} />
                                    <a style={{
                                        display: "inline-block",
                                        marginLeft: "10px"
                                    }} href="#/privacy">Privacy policy</a>
                                </div>
                                <div>
                                    <input type="checkbox" onClick={() => {
                                        this.setState({
                                            isTermsAccepted: !this.state.isTermsAccepted,
                                        })
                                    }} checked={this.state.isTermsAccepted} value={this.state.isTermsAccepted} />
                                    <a style={{
                                        display: "inline-block",
                                        marginLeft: "10px"
                                    }} href="#/termsofuse">Terms of use</a>
                                </div>
                                <div>
                                    <input type="checkbox" onClick={() => {
                                        this.setState({
                                            isSalesAccepted: !this.state.isSalesAccepted,
                                        })
                                    }} checked={this.state.isSalesAccepted} value={this.state.isSalesAccepted} />
                                    <a style={{
                                        display: "inline-block",
                                        marginLeft: "10px"
                                    }} href="#/termsofsales">Terms of sales</a>
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-6"}>

                        </div>
                    </div>
                    <p className={"alert alert-primary"}>You must agree to our privacy policy and terms of use before activation</p>

                    <p style={{ textAlign: "center" }} className={"alert alert-warning"}>
                        Activate tv device by voucher code for LG / Samsung Qa team
                        <hr />
                        <button
                            onClick={() => {
                                this.setState({
                                    voucher_code_activation_popup: true
                                })
                            }}
                            className={"btn btn-success"}
                        >Active</button>
                    </p>

                </form>
                <div className={"col-lg-6 aside-block"}>
                    <div className={"alert alert-danger"}>
                        Important! We do not provide any content with this app and you must have a playlist to use it. Please make sure you have a playlist before purchase.
                    </div>
                    <h1>Pay once and activate your device forever</h1>
                    <ol>
                        <li>Enter your email address</li>
                        <li>Enter your mac address</li>
                        <li>Agree with our privacy policy and terms of use</li>
                        <li>Hit Pay and join</li>
                    </ol>


                    <p className={"price-not"}>The activation price is a one time payment per MAC address of <span>{this.state.priceMad}</span>  (Approximately <span>{this.state.priceEuro}</span>)</p>
                </div>
            </div>
        )
    }
}


export default Payment;
