import React, {Component} from 'react';
import SimpleReactValidator from "simple-react-validator";
import {addNewPlaylist, addPlaylistFromFile} from "../server";
import Loading from "./Loading";
import {toast, ToastContainer} from "react-toastify";
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
class NewPlaylist extends Component{
    constructor(props) {
        super(props);
        this.state = {
            name : "",
            url : "",
            isLoading : false,
            authType : "user",
            progress:0,
            type : "url"
        }

        this.validator = new SimpleReactValidator();
        this.device = this.props.location.query.device;
        this.mac = this.props.location.query.mac;
        this.type = this.props.location.query.type;
        this.id = this.props.location.query.id;
        this.name = this.props.location.query.name;
    }

    componentDidMount() {
        let auth = this.props.location.query.auth;
        this.setState({type:this.type})
        if (auth){
            window.localStorage.setItem('auth',auth);
            this.setState({
                authType : auth
            })
        }
        if (this.device){
            this.setState({
                name : "playlist for " + this.device
            })
        }

    }

    formValid(validator,filedName) {
        if (validator.fieldValid(filedName)) {
            return true;
        } else {
            validator.showMessages();
            this.forceUpdate();
            return  false;
        }
    }

    hideForceLoading(){
            setTimeout(()=>{
                this.setState({isLoading:false})
            },2000);

    }
    addPlaylist(){

        let elem = document.getElementById("file");
        let file = elem.files[0];
        if (file){
            if (!this.formValid(this.validator,"name")){
                toast.error("name of the playlist required");
                return;
            }
            let formData = new FormData();
            let type = file.name.split(".")[file.name.split(".").length-1];
            if (type === "m3u8" || type === "m3u"){
                formData.append("file",file);
                formData.append("isFromFile",1);
                formData.append("name",this.state.name);
                formData.append("mac",  this.mac);
                addPlaylistFromFile(formData,()=>{
                    this.setState({isLoading:false})
                    window.location.href = "#/account/devices"
                    window.location.reload();
                },(data)=>{
                   this.setState({progress : data})
                })
            }else{
                toast.error("Invalid file. Use only (m3u,m3u8) files")
            }
        }else{
            if (this.formValid(this.validator,"url") && this.formValid(this.validator,"name")){
                this.setState({isLoading:true})
                addNewPlaylist({mac:this.mac,name:this.state.name,url:this.state.url},(data)=>{
                    setTimeout(()=>{
                        this.setState({isLoading:false})
                        window.location.href = "#/account/devices"
                        window.location.reload();
                    },1000);
                });
                this.hideForceLoading();
            }
        }
    }

    onChangeCaptcha(){}
    setFile(e){
        console.log(e.files);
    }
    render() {
        let loading = "";
        if (this.state.isLoading){
            loading= <Loading/>
        }
        return(
            <div className={"row"}>
                <ToastContainer/>
                {loading}
                <div className={"col-lg-6 left-block"}>
                    <h1 className={"heading"}>Your device mac {this.mac}</h1>
                    <div className={"form-group"}>
                        <label htmlFor="playlistname">Enter your playlist name</label>
                        <span className={"error-message"}>{this.validator.message('name', this.state.name, 'required')}</span>
                        <input value={this.state.name} onChange={(event)=>{
                            this.setState({
                                name : event.target.value
                            })
                        }} type="text" id={"playlistname"} className={"form-control"}/>
                    </div>
                    <div className={"form-group"}>
                        <label htmlFor="playlisturl">Enter your playlist url</label>
                        <span className={"error-message"}>{this.validator.message('url', this.state.url, 'required')}</span>
                        <input value={this.state.url} onChange={(event)=>{
                            this.setState({
                                url : event.target.value
                            })
                        }} type="text" id={"playlisturl"} className={"form-control"}/>
                    </div>

                    <p className={"separator"}> OR </p>

                    <div className={"form-group"}>
                        <label htmlFor="file">Choose playlist file</label>
                        <input type="file" id={"file"}/>
                    </div>


                    <div className={"bottom-form-block"} >
                        {this.state.progress > 0 ? <Progress type="circle"
                                                              percent={this.state.progress}
                                                              theme={
                                                                  {
                                                                      active: {
                                                                          symbol: this.state.progress + '%',
                                                                          trailColor: 'white',
                                                                          color: '#ff6600'
                                                                      },
                                                                      success: {
                                                                          symbol: this.state.progress + '%',
                                                                          trailColor: 'lime',
                                                                          color: 'green'
                                                                      }
                                                                  }
                                                              }
                        />: ""}

                        <div className={"form-group"}>
                            <button style={{
                                marginTop: "12px"
                            }} onClick={()=>this.addPlaylist()} className={"btn btn-success"}>Add new playlist</button>
                        </div>
                    </div>
                </div>
                <div className={"col-lg-6 aside-block"}>
                    <h1>Add new playlist</h1>
                    <p>Add your playlist from url or file</p>
                </div>
            </div>
        )
    }
}

export default NewPlaylist;