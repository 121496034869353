import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import { confirmResetPassword, resetPassword } from "../server";
class Reset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      code: "",
      new_password: "",
      code_sent: false,
    };
  }

  componentDidMount() {}

  resetPassword() {
    resetPassword(
      this.state.email,
      (data) => {
        this.setState({ code_sent: true });
      },
      (err) => {
        toast.error(err.message);
      }
    );
  }

  confirmCodeAndReset() {
    confirmResetPassword(
      this.state.code,
      this.state.email,
      this.state.password,
      (data) => {
        toast.success("Password reset done");
        setTimeout(() => {
          window.location.href = "#/login";
        }, 2000);
      },
      (err) => {
        toast.error(err);
      }
    );
  }

  render() {
    return (
      <div className={"row"}>
        <ToastContainer />
        <div className={"col-lg-6 left-block"}>
          <div className={"form-group"}>
            <label htmlFor="email">Enter your email address</label>
            <input
              type="text"
              onInput={(event) => {
                this.setState({ email: event.target.value });
              }}
              value={this.state.email}
              id={"email"}
              className={"form-control"}
            />
          </div>
          <div className={"form-group"}>
            <div className={"gravity-right login-account__gravity"}>
              <a className={"bottom-link"} href="#/login">
                Login to your account
              </a>

              <button
                onClick={() => this.resetPassword()}
                className={"btn btn-success"}
              >
                Get confirmation code
              </button>
            </div>
          </div>

          {this.state.code_sent ? (
            <div>
              <h4>
                Please check you email (<span>{this.state.email}</span>). We
                have sent confirmation code
              </h4>
              <div className={"form-group"}>
                <label>Enter confirm code from email</label>
                <input
                  className={"form-control"}
                  type="number"
                  value={this.state.code}
                  onInput={(event) => {
                    this.setState({ code: event.target.value });
                  }}
                />
              </div>
              <div className={"form-group"}>
                <label>Enter new password</label>
                <input
                  className={"form-control"}
                  type="password"
                  value={this.state.password}
                  onInput={(event) => {
                    this.setState({ password: event.target.value });
                  }}
                />
              </div>
              <div className={"gravity-right"}>
                <button
                  onClick={() => this.confirmCodeAndReset()}
                  className={"btn btn-success"}
                >
                  Reset password
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className={"col-lg-6 aside-block"}>
          <h1>Reset your password</h1>
          <p className={"steps"}>
            Enter your email address we will send you a new password.
          </p>
        </div>
      </div>
    );
  }
}

export default Reset;
