import React, { Component } from 'react';
import SimpleReactValidator from "simple-react-validator";
import { registerUser } from "../server";
import Loading from "./Loading";
class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            isLoading: false
        }
        this.validator = new SimpleReactValidator();
    }

    hideForceLoading() {
        setTimeout(() => {
            this.setState({ isLoading: false })
        }, 2000);
    }
    formValid(validator, filedName) {
        if (validator.fieldValid(filedName)) {
            return true;
        } else {
            validator.showMessages();
            this.forceUpdate();
            return false;
        }
    }

    register(e) {
        e.preventDefault();
        if (this.formValid(this.validator, "email") && this.formValid(this.validator, "password")) {
            this.setState({ isLoading: true })
            registerUser({ email: this.state.email, password: this.state.password }, (data) => {
                this.setState({
                    isLoading: false
                })
                window.localStorage.setItem("token", data.message);
                window.localStorage.setItem("email", this.state.email);
                window.location.href = "#/account/devices";
            })
            this.hideForceLoading();
        }
    }



    render() {
        let loading = "";
        if (this.state.isLoading) {
            loading = <Loading />
        }
        return (
            <div className={"row"}>
                {loading}
                <form className={"col-lg-6 left-block"} onSubmit={(e) => this.register(e)}>
                    <div className={"form-group"}>
                        <label htmlFor="email">Enter your email</label>
                        <span className={"error-message"}>{this.validator.message('email', this.state.email, 'required|email')}</span>
                        <input onChange={(event) => {
                            this.setState({
                                email: event.target.value
                            })
                        }} value={this.state.email} type="text" id={"email"} className={"form-control"} />
                    </div>
                    <div className={"form-group"}>
                        <label htmlFor="password">Enter new password</label>
                        <span className={"error-message"}>{this.validator.message('password', this.state.password, 'required')}</span>
                        <input onChange={(event) => {
                            this.setState({
                                password: event.target.value
                            })
                        }} value={this.state.password} type="password" id={"password"} className={"form-control"} />
                    </div>
                    <div className={"form-group"}>
                        <div className={"gravity-right"}>
                            <button
                                className={"btn btn-success"}>
                                Registration
                            </button>
                        </div>
                    </div>
                </form>
                <div className={"col-lg-6 aside-block"}>
                    <h1>Create account for managing multiple devices</h1>
                    <ol>
                        <li>Enter your email address</li>
                        <li>Enter your password</li>
                        <li>Hit register</li>
                    </ol>
                </div>
            </div>
        )
    }
}

export default Registration;