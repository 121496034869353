import React from 'react';
import Loading from "./Loading";

class Start extends React.Component {
    componentDidMount() {
        window.location.href = "#/payment"
    }
    render() {
        return (<div>
            <Loading />
        </div>)
    }
}

export default Start;